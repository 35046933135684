import React, { useEffect, useRef } from 'react';
import { init } from 'ityped';
import logo from '../assets/Logo2preview22.webp';
import image1 from '../assets/background.webp';
import image2 from '../assets/chris.webp';
import image3 from '../assets/BBQ.webp';
import image4 from '../assets/sd.webp';
import solanaLogo from '../assets/solanaLogo.png';
import metaplexlogo from '../assets/No Background - Horizontal White M + Name.png';
import Civillogo from '../assets/Logo2preview22.webp';
import TrumpMAGAlogo from '../assets/Trump_MAGA_logo_2024.svg.png';
import Visitseattlelogo from '../assets/VisitSeattle_white_Meetings.png';
import '../pagescss/Civil.css';

const Civil = () => {
    const scrollContainerRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current && !textRef.current.itypedInitialized) {
            init(textRef.current, {
                strings: ['Great', 'FOMO', 'Power', 'Shine', 'Pump', 'Moon'],
                typeSpeed: 168,
                backSpeed: 50,
                startDelay: 800,
                backDelay: 1000,
                loop: true,
                showCursor: true,
                cursorChar: '_ ',
            });
            textRef.current.itypedInitialized = true;
        }
    }, []);

    const handleMouseEnter = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'paused';
        }
    };

    const handleMouseLeave = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'running';
        }
    };

    const scrollItems = [
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
        { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
        { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
        { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
        { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
        { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' }
    ];

    return (
        <section id="Civil">
            <div className="civil-content">
                <img src={logo} alt="Welcome to Civilianism Club" className="Civil-title-image" />
                <p>
                    This right here's a bunch of WEB3 folks and their friends. They're all about freedom and equality, can't stand the rich-poor divide, and got no time for them high and mighty types. They're loud and proud, fixin' to build their own independent, rebellious community from scratch. They're all about breakin' the mold and livin' wild and free.
                </p>
                <h2 className="display-4">
                    Make NFTs <span ref={textRef} className="ityped-text"></span>Again!
                </h2>

                <div className="image-gallery">
                    <img src={image1} alt="Gallery 1" />
                    <img src={image2} alt="Gallery 2" />
                    <img src={image3} alt="Gallery 3" />
                    <img src={image4} alt="Gallery 4" />
                </div>
            </div>
            <div className="divider-line"></div>
            <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={scrollContainerRef}
            >
                <div className="scroll-content">
                    {scrollItems.map((item, index) => (
                        <a href={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Civil;