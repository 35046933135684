
import '../pagescss/Mission.css';
import image3 from '../assets/Mission.webp';
import solanaLogo from '../assets/solanaLogo.png';
import metaplexlogo from '../assets/No Background - Horizontal White M + Name.png';
import Civillogo from '../assets/Logo2preview22.webp';
import TrumpMAGAlogo from '../assets/Trump_MAGA_logo_2024.svg.png';
import Visitseattlelogo from '../assets/VisitSeattle_white_Meetings.png';
import React, { useRef } from 'react';

const scrollItems = [
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' }
];

const Mission = () => {
    const scrollContainerRef = useRef(null);


    const handleMouseEnter = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'paused';
        }
    };

    const handleMouseLeave = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'running';
        }
    };
    return (
        <section id="Mission">
            <div className="mission-container">
                <div className="mission-content">
                    <div className="mission-row">
                        <div className="mission-text">
                            <p>Tune out the noise, keep an open mind, let go of who you think you are, and focus on building together. NFTs can be for everyone.</p>
                            <p>You don't gotta care about Civils, but you gotta have one — it's the standard. </p>
                        </div>

                        {/*
                                                <div className="mission-text">

                        </div>
                        <div className="mission-image">
                            <img src={image1} alt="Focused on building" />
                        </div>
                        <div className="mission-image">
                            <img src={image2} alt="NFTs can be for everyone" />
                        </div>
*/}
                    </div>

                    <div className="mission-row">


                    </div>
                    <div className="mission-final">
                        <img src={image3} alt="NFTs can be for everyone" />
                    </div>

                </div>
            </div>
            <div className="divider-line"></div>
            <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={scrollContainerRef}
            >
                <div className="scroll-content">
                    {scrollItems.map((item, index) => (
                        <a href={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Mission;